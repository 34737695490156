@font-face {
    font-family: "drabc";
    src: url("/resources/drAbcRegular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

body {
    position: relative;
    height: 100%;
    min-width: 768px;
    font-family: "Segoe UI";
}
#root {
}
#siteFooter {
    background: #fff;
    height: 60px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    color: #000;
    line-height: 60px;
    text-align: center;
    margin: auto;
}

#siteFooter a {
    margin-right: 20px;
}

#navBar {
    /* Style for "Rectangle" */

    outline: 1px solid #c1c1c1;
    background-color: #fff !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
}

#navBar .navbar-nav li a {
    font-size: 13pt;
}

#navBar > .row {
    height: 100%;
}

#navBar .right > ul > li {
    display: block;
    height: 60px;
}

#pageTitle {
    text-align: center;
    font-size: 20pt;
    line-height: 60px;
    color: #565656;
}
#navUser {
    line-height: 43px;
    font-size: 13pt;
    color: #707070;
    text-transform: capitalize;
}
#navSub {
    height: auto;
}
#navLogo {
    font-size: 14pt;
    margin-top: 6px;
}
#navLogo a {
    text-decoration: none;
    color: #707070;
}
#navLogo img {
    margin-right: 10pt;
}
#navLogo .highlight {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: #ffa319;
}
#navLogo .highlight.a {
    color: #ff738e;
}
#navLogo .highlight.b {
    color: #be68ff;
}
#navLogo .highlight.c {
    color: #53d41d;
}

#navBar + #mainContent {
    padding-top: 75px;
    padding-bottom: 75px;
}

#classRoomBackBtn a {
    color: #707070;
    font-size: 14pt;
    line-height: 43px;
    text-transform: capitalize;
}

#mainContent {
    position: relative;
    min-height: 600px;
}
#mainContent > .bg {
    background: url("/portal-images/bg.png") bottom center no-repeat;
    background-size: cover;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 600px;
}

.container.white {
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 3px 6px #a2a2a2;
    min-height: 90%;
    padding-top: 30px;
    padding-bottom: 30px;
}

.pageHead {
    font-size: 22pt;
    color: #fff;
    margin-bottom: 20px;
}

/* completedChallenge */
.completedChallenge.bg {
    background-image: url("/portal-images/bg_skyCloud.png");
}

/* Extra small devices (portrait phones) */
@media (max-width: 575px) {
}

/* Small devices (landscape phones) */
@media (max-width: 767px) {
}

@media (max-width: 800px) {
    #mainContent > .bg {
        background-position-x: -600px;
    }
}

/* // Medium devices (tablets) */
@media (max-width: 991px) {
    #mainContent > .bg {
        background-position-x: -300px;
    }
}

/* // Large devices (desktops) */
@media (max-width: 1199px) {
}

/* // extra Large devices (FHD desktops) */
@media (max-width: 1499px) {
}

/*  */
/*  */
/*  */

/* Extra small devices (portrait phones) */
@media (min-width: 576px) {
}

/* Small devices (landscape phones) */
@media (min-width: 768px) {
}

/* // Medium devices (tablets) */
@media (min-width: 992px) {
}

/* // Large devices (desktops) */
@media (min-width: 1200px) {
}

/* // extra Large devices (FHD desktops) */
@media (min-width: 1500px) {
}
