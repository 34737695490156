.letterLines .letter_upper_a {
}

.letterLines .letter_lower_a {
}

.letterLines .letter_upper_b {
    bottom: -17px;
    transform: scale(0.8);
}

.letterLines .letter_lower_b {
}

.letterLines .letter_upper_c {
    bottom: -5px;
    transform: scale(0.95);
}

.letterLines .letter_lower_c {
    bottom: -26px;
    transform: scale(0.55);
}

.letterLines .letter_upper_d {
    bottom: -11px;
    transform: scale(0.88);
}

.letterLines .letter_lower_d {
    bottom: -27px;
}

.letterLines .letter_upper_e {
    bottom: -34px;
    transform: scale(0.63);
}

.letterLines .letter_lower_e {
    bottom: -23px;
}

.letterLines .letter_upper_f {
    bottom: -34px;
    transform: scale(0.62);
}

.letterLines .letter_lower_f {
    bottom: -59px;
    transform: scale(0.5);
}

.letterLines .letter_upper_g {
    bottom: 3px;
    transform: scale(1.1);
}

.letterLines .letter_lower_g {
    bottom: -59px;
}

.letterLines .letter_upper_h {
    bottom: -18px;
    transform: scale(0.85);
}

.letterLines .letter_lower_h {
    bottom: -40px;
    transform: scale(0.56);
}

.letterLines .letter_upper_i {
    bottom: -300px;
    transform: scale(0.17);
}

.letterLines .letter_lower_i {
    bottom: -220px;
    transform: scale(0.18);
}

.letterLines .letter_upper_j {
    bottom: -36px;
    transform: scale(0.65);
}

.letterLines .letter_lower_j {
    bottom: -160px;
    transform: scale(0.2);
}

.letterLines .letter_upper_k {
    bottom: -25px;
    transform: scale(0.8);
}

.letterLines .letter_lower_k {
    bottom: -70px;
    transform: scale(0.34);
}

.letterLines .letter_upper_l {
    bottom: -38px;
    transform: scale(0.62);
}

.letterLines .letter_lower_l {
    bottom: -362px;
    transform: scale(0.14);
}

.letterLines .letter_upper_m {
    bottom: -12px;
    transform: scale(1.2);
}

.letterLines .letter_lower_m {
    bottom: -06px;
    transform: scale(01);
}

.letterLines .letter_upper_n {
    bottom: -36px;
    transform: scale(0.88);
}

.letterLines .letter_lower_n {
}

.letterLines .letter_upper_o {
    bottom: -2px;
    transform: scale(1.05);
}

.letterLines .letter_lower_o {
    bottom: -26px;
}

.letterLines .letter_upper_p {
    bottom: -28px;
    transform: scale(0.75);
}

.letterLines .letter_lower_p {
    bottom: -65px;
}

.letterLines .letter_upper_q {
    bottom: -24px;
    transform: scale(1.1);
}

.letterLines .letter_lower_q {
    bottom: -60px;
}

.letterLines .letter_upper_r {
    bottom: -30px;
    transform: scale(0.76);
}

.letterLines .letter_lower_r {
    bottom: -50px;
    transform: scale(0.4);
}

.letterLines .letter_upper_s {
    bottom: -21px;
    transform: scale(0.8);
}

.letterLines .letter_lower_s {
    bottom: -38px;
    transform: scale(0.47);
}

.letterLines .letter_upper_t {
    bottom: -16px;
    transform: scale(0.87);
}

.letterLines .letter_lower_t {
    bottom: -58px;
    transform: scale(0.35);
}

.letterLines .letter_upper_u {
    bottom: -15px;
    transform: scale(0.85);
}

.letterLines .letter_lower_u {
}

.letterLines .letter_upper_v {
    bottom: -20px;
    transform: scale(1.08);
}

.letterLines .letter_lower_v {
    bottom: -38px;
}

.letterLines .letter_upper_w {
    bottom: -5px;
    transform: scale(1.45);
}

.letterLines .letter_lower_w {
    bottom: -20px;
    transform: scale(0.9);
}

.letterLines .upperSVG + .lowerSVG {
    margin-left: 30px;
}

.letterLines .letter_upper_x {
    bottom: -16px;
    transform: scale(0.95);
}

.letterLines .letter_lower_x {
    bottom: -37px;
    transform: scale(0.55);
}

.letterLines .letter_upper_y {
    bottom: -10px;
    transform: scale(0.95);
}

.letterLines .letter_lower_y {
    bottom: -65px;
    transform: scale(0.6);
}

.letterLines .letter_upper_z {
    bottom: -2px;
    transform: scale(1.04);
}

.letterLines .letter_lower_z {
    bottom: -25px;
    transform: scale(0.6);
}
