/* board */
#boardRow {
    height: 100%;
}
#boardCol {
    display: flex;
    align-self: center;
    overflow: hidden;
}
#board {
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("/images/board_md.png");
    height: 423px;
    width: 750px;
    margin: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}
#boardBody {
    height: 280px;
    width: 520px;
    margin: auto;
    position: relative;
}
#boardTitleRow {
    height: 40px;
}
#board #drIcon {
    position: absolute;
    width: 60%;
    left: 10px;
    top: -10px;
}
#board #soundIcon {
    position: absolute;
    width: 60%;
    right: 15px;
    top: -0px;
    cursor: pointer;
}

#board .titleWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#board .title {
    font-family: "drabc";
    color: #ffffff;
    font-size: 17pt;
    text-align: center;
}
#boardContentRow {
    font-family: "drabc";
    color: #ffffff;
    font-size: 17pt;
    text-align: left;
}
#boardContentRow p:first-child {
    margin-bottom: 3%;
}
#boardContentRow b {
    color: #ffbb00;
}

#board #leftBtn,
#board #rightBtn {
    width: 136px;
    height: 105px;
    background-image: url(/images/leftBtn_hov.png);
    background-image: url(/images/rightBtn_hov.png);
    background-image: url(/images/leftBtn.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60%;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -52px;
    cursor: pointer;
}
#board #leftBtn {
}
#board #rightBtn {
    background-image: url(/images/rightBtn.png);
    left: auto;
    right: 0;
}
#board #leftBtn:hover {
    background-image: url(/images/leftBtn_hov.png);
}
#board #rightBtn:hover {
    background-image: url(/images/rightBtn_hov.png);
}

#board p.active {
    color: #ffbb00;
}

/* Extra small devices (portrait phones) */
@media (min-width: 576px) {
}
/* Small devices (landscape phones) */
@media (min-width: 768px) {
}
/* // Medium devices (tablets) */
@media (min-width: 992px) {
    #board {
        background-image: url("/images/board_lg.png");
        height: 560px;
        width: 950px;
    }
    #boardBody {
        height: 360px;
        width: 720px;
    }
    #boardTitleRow {
        height: 72px;
    }
    #board .title {
        font-size: 20pt;
    }
    #boardContentRow {
        font-size: 20pt;
    }
}

/* // Large devices (desktops) */
@media (min-width: 1200px) {
    #board {
        background-image: url("/images/board_xl.png");
        height: 630px;
        width: 1070px;
    }
    #boardBody {
        height: 410px;
        width: 800px;
    }
    #boardTitleRow {
        height: 82px;
    }
    #board .title {
        font-size: 25pt;
    }
    #boardContentRow {
        font-size: 23pt;
    }
}
/* // extra Large devices (FHD desktops) */
@media (min-width: 1500px) {
    #board {
        background-image: url("/images/board_xxl.png");
        height: 780px;
        width: 1360px;
    }
    #boardBody {
        height: 510px;
        width: 1020px;
    }
    #soundIcon {
        top: 0;
    }
    #boardTitleRow {
        height: 102px;
    }
    #board .title {
        font-size: 31pt;
    }
    #boardContentRow {
        font-size: 29pt;
    }
    #board #leftBtn,
    #board #rightBtn {
        background-size: 75%;
        left: 20px;
    }
    #board #rightBtn {
        background-size: 75%;
        left: auto;
        right: 20px;
    }
}

#Layer_1 {
    width: 100px;
}

.upperSVG svg g *:not(.cover) {
    stroke: #fff;
}

.upperSVG svg g .cover,
.lowerSVG svg g .cover {
    fill: #00ad46 !important;
}

.animate1 {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    stroke: #fff !important;
    animation: dash 1s ease-in 0s forwards;
}
.animate2 {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    stroke: #fff !important;
    animation: dash 1s ease-in 0s forwards;
}
.animate3 {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    stroke: #fff !important;
    animation: dash 1s ease-in 0s forwards;
}
@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

.letterLines {
    max-width: 360px;
    height: 110px;
    border: 0;
    border-top: 1px solid #ffbb00;
    border-bottom: 1px solid #ffbb00;
    position: relative;
    margin: auto;
}
.letterLines > div.mid {
    border-bottom: 1px dashed #ffbb00;
    height: 50px;
}
.letterLines .upperSVG,
.letterLines .lowerSVG {
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -56px;
}
.letterLines .lowerSVG {
    bottom: -30px;
    transform: scale(0.6);
}
.letterLines .upperSVG + .lowerSVG {
    margin-left: -10px;
}
.letterLines span.upperSVG {
    margin-left: -100px;
}
