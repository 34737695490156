#mainContent.class {
    background: url("/portal-images/bg2.png") no-repeat top center #266816;
    background-size: 100% auto;
}
#mainContent.class > .bg {
    display: none;
}
#classLogin {
    margin-top: 0px;
    font-family: "drabc";
}

#classLogin .msg {
    background: url("/portal-images/textBox_mobile.png") no-repeat top center;
    background-size: 100% 100%;
}
#classLogin .msg > div {
    padding: 30px 0 30px 30px;
}
#classLogin .msg .line1 {
    font-size: 14pt;
}

#classLogin .msg .line2 {
    font-size: 20pt;
}

.nameCardWrap {
    padding: 8px;
    border-radius: 52px;
    cursor: pointer;
}
.nameCardWrap.active {
    border: 3px solid #ff9900;
}

.nameCard {
    /* width: 349px; */
    height: 120px;
    border-radius: 52px;
}

.blueNameCard {
    /* Style for "Rectangle" */

    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border: 4px solid #94c9f6;
    background-image: linear-gradient(180deg, #1c97ff 0%, #5db4ff 100%);
}

.orangeNameCard {
    /* Style for "Path 2648" */
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3);
    border: 4px solid #fca63f;
    background-image: linear-gradient(180deg, #e57123 0%, #fb7654 100%);
}

.purpleNameCard {
    /* Style for "Rectangle" */
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border: 4px solid #bb87f9;
    background-image: linear-gradient(180deg, #833ee5 0%, #5f08e2 100%);
}

.greenNameCard {
    /* Style for "Rectangle" */
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border: 4px solid #bb87f9;
    background-image: linear-gradient(180deg, #833ee5 0%, #5f08e2 100%);
}

.yellowNameCard {
    /* Style for "Rectangle" */
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border: 4px solid #f9db5a;
    background-image: linear-gradient(180deg, #fbc93c 0%, #ff9d09 100%);
}

.redNameCard {
    /* Style for "Rectangle" */
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border: 4px solid #fc86c2;
    background-image: linear-gradient(180deg, #e83d83 0%, #f8704d 100%);
}

.nameCard table {
    margin: auto;
    width: 100%;
    height: 100%;
}
.nameCard table td {
    vertical-align: middle;
    width: 50%;
}
.nameCard .icon {
    padding-left: 20px;
}
.nameCard .icon div {
    background: #fff;
    border-radius: 30px;
    height: 90px;
    width: 90px;
    padding: 15px;
    display: table-cell;
    vertical-align: middle;
}
.nameCard .icon img {
    max-width: 100%;
    max-height: 100%;
}

.nameCard .name {
    text-align: left;
    font-size: 12pt;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    font-family: "drabc";
    padding-right: 8px;
}

#studentLoginBtn {
    /* Style for "Rectangle" */
    width: 300px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    background-color: #ff8500;
    font-family: "Segoe UI";
    color: #fff;
    font-size: 20pt;
    font-weight: bold;
    margin: auto;
    padding: 10px;
    cursor: pointer;
    border: none;
}

#studentLoginBtn:active {
    background-color: #c96c08;
}
#studentLoginBtn:disabled {
    cursor: default;
    background-color: #e6e6e6;
}

#classBeginTestBtn {
    width: 100%;
    padding: 20px;
    text-align: center;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    background-color: #ff8500;
    color: #fff;
    font-family: "Segoe UI";
    font-size: 20pt;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 30px;
    border: none !important;
}

#classSwitchUserBtn {
    width: 100%;
    padding: 20px;
    text-align: center;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    background-color: #e6e6e6;
    color: #666;
    font-family: "Segoe UI";
    font-size: 20pt;
    font-weight: 600;
    border: none !important;
}

#classBeginTestBtn:active {
    background-color: #c96c08;
}
#classSwitchUserBtn:active {
    background-color: #ccc;
}

/* challenge */
#challengeCarousel {
}
#challengeCarousel .navBack {
    position: absolute;
    top: 50%;
    height: 100%;
    left: -5%;
    margin-top: -3%;
}
#challengeCarousel .navNext {
    position: absolute;
    top: 50%;
    height: 100%;
    right: -5%;
    margin-top: -3%;
}

#challengeQuestionNum {
    width: 268px;
    position: absolute;
    left: 50%;
    margin-left: -134px;
    top: -55px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 22px;
    background-color: #ff9900;
    text-align: center;
    padding: 10px;
    color: #fff;
    font-family: "drabc";
    font-size: 18pt;
}

#challengeCarousel_optWrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

#challengeCarousel_optWrap .optWrap {
    margin: 20px 10px;
}

#challengeCarousel_optWrap .optWrap.active {
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    border: 3px solid #ff9900;
}
#challengeCarousel_optWrap .optWrap.answer {
    border: 3px solid greenyellow;
    border-radius: 40px;
}
.challengeOpt {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    border: 5px solid #ececec;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    text-align: left;
}

.challengeOpt.box {
    width: 180px;
    height: 180px;
    font-size: 20pt;
    text-align: center;
}
.challengeOpt.list {
    padding: 20px;
    width: 400px;
    font-size: 18px;
}

.challengeOpt_red {
    background-image: radial-gradient(
        109px 109px at 260.45% 302.27%,
        #ff7878 0%,
        #fb3c5a 100%
    );
}
.challengeOpt_lightbBlue {
    background-image: radial-gradient(
        109px 109px at 377.73% 302.27%,
        #2699fb 0%,
        #178bee 100%
    );
}
.challengeOpt_green {
    background-image: radial-gradient(
        110px 110px at 495% 302.27%,
        #69f686 0%,
        #2ee164 100%
    );
}
.challengeOpt_darkBlue {
    background-image: radial-gradient(
        110px 110px at 612.27% 302.27%,
        #8089ff 0%,
        #6b76ff 100%
    );
}

.challengeOpt.box span.label {
    font-size: 14pt;
    position: absolute;
    bottom: 10px;
    right: 20px;
}

#challengeCarousel .challengeCarousel_btnGroups button {
    border-radius: 20px;
    padding: 20px 40px;
}

/* classroom selection */
#classroomSelection {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    color: #ffffff;
    font-family: "Segoe UI";
    font-size: 30pt;
    font-weight: 700;
    margin-bottom: 30px;
    position: relative;
}

#classroomSelection > div {
    font-size: 14pt;
}

#schoolLanding .gradeBox {
    width: 300px;
    height: 260px;
    box-shadow: 0 3px 6px #a2a2a2;
    border-radius: 36px;
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 20px;
}

#schoolLanding .gradeBox .header {
    color: #2699fb;
    font-family: "Segoe UI";
    font-size: 20pt;
    font-weight: 700;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
#schoolLanding .gradeBox .classLink {
    font-size: 14pt;
}

/* challenge progress bar */

#challengeProgressBar {
    width: 80%;
    height: 5px;
    margin: auto;
    border-radius: 1px;
    background-color: #ffffff;
    position: relative;
    margin-top: 80px;
}

#challengeProgressBar .progress {
    height: 5px;
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 1px;
    background-color: #69f686;
}

#challengeProgressBar .endDot {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: #fbfffe;
    opacity: 0.1;
    position: absolute;
    right: -24px;
    top: -21px;
}
#challengeProgressBar .endKnob {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #ffd500;
    right: -6px;
    top: -3px;
    position: absolute;
}

#challengeProgressBar .progressDot {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: #fbfffe;
    opacity: 0.1;
    position: absolute;
    top: -21px;
    margin-left: -24px;
}
#challengeProgressBar .progressKnob {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #ffd500;
    margin-left: -6px;
    top: -3px;
    position: absolute;
}
#challengeProgressBar .car {
    background: url("/portal-images/yellowCar.png");
    width: 73px;
    height: 40px;
    position: absolute;
    margin-left: -36px;
    top: -50px;
}

#challengeTimer {
    height: 123px;
    position: relative;
}
#challengeTimer .body {
    background: url("/portal-images/timeStamp_sm.png");
    width: 141px;
    height: 123px;
    position: absolute;
    right: 50px;
    top: 2px;
}

#challengeTimer .timer {
    color: #ffffff;
    font-family: "Segoe UI";
    font-size: 21pt;
    font-weight: 600;
    position: relative;
    top: 14px;
    left: 17px;
}

/* Extra small devices (portrait phones) */
@media (min-width: 576px) {
}

/* Small devices (landscape phones) */
@media (min-width: 768px) {
    .challengeOpt.box {
        width: 200px;
        height: 160px;
    }
    .challengeOpt.list {
        width: 480px;
    }
}

/* // Medium devices (tablets) */
@media (min-width: 992px) {
    #classLogin {
        margin-top: 0px;
    }
    .challengeOpt.box {
        width: 180px;
        height: 140px;
    }

    .challengeOpt.list {
        width: 400px;
    }
}

/* // Large devices (desktops) */
@media (min-width: 1200px) {
}

/* // extra Large devices (FHD desktops) */
@media (min-width: 1400px) {
}

/* // extra Large devices (FHD desktops) */
@media (min-width: 1500px) {
    #classLogin {
        margin-top: 40px;
    }
    .challengeOpt.list {
        width: 600px;
    }
}
