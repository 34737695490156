.classBox {
    width: 100%;
    height: 220px;
    box-shadow: 0 3px 6px #a2a2a2;
    border-radius: 36px;
    background-color: #ffffff;
    margin-bottom: 30px;
    padding: 10px 20px;
    position: relative;
}

.classBox hr {
    border: 1px solid #8089ff;
    margin: 15px 0px;
}

.classBox .enterBtn {
    width: 120px;
    text-align: center;
    position: absolute;
    bottom: 15px;
    left: 50%;
    margin-left: -60px;
}

.unitBox {
    width: 100%;
    box-shadow: 0 3px 6px #a2a2a2;
    border-radius: 36px;
    background-color: #ffffff;
    margin-bottom: 30px;
    padding: 15px 20px 30px;
    position: relative;
    font-size: 15pt;
}

.unitBox .head .lead {
    font-size: 20pt;
    color: #565656;
}

.unitBox hr {
    border: 1px solid #8089ff;
    margin: 15px 0px;
}

/* Extra small devices (portrait phones) */
@media (max-width: 575px) {
}

/* Small devices (landscape phones) */
@media (max-width: 767px) {
}

/* // Medium devices (tablets) */
@media (max-width: 991px) {
}

/* // Large devices (desktops) */
@media (max-width: 1199px) {
}

/* // extra Large devices (FHD desktops) */
@media (max-width: 1499px) {
}
