#pageCover {
    position: relative;
}

#logo {
    background-image: url(/portal-images/Dr_ABC_logo.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 153px;
    width: 202px;
    top: 60px;
    position: absolute;
}
#pageCover .welcome {
    position: absolute;
    top: 80px;
    left: 230px;
    font-size: 30pt;
    font-weight: 300;
    color: #fff;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
#pageCover .welcome .highlight {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: #ffa319;
    font-size: 30pt;
}
#pageCover .welcome .highlight.a {
    color: #ff738e;
}
#pageCover .welcome .highlight.b {
    color: #be68ff;
}
#pageCover .welcome .highlight.c {
    color: #53d41d;
}
#pageCover .text3 {
    color: #fff;
    font-size: 20pt;
    font-weight: 700;
    position: absolute;
    top: 260px;
    left: 35px;
    text-shadow: 0 3px 20px rgba(0, 0, 0, 1);
}

#loginBtn {
    position: absolute;
    top: 360px;
    left: 35px;
    width: 216px;
    height: 55px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 38px;
    background-image: linear-gradient(180deg, #ffffff 0%, #fff6ff 100%);
    font-size: 20pt;
    font-weight: 600;
    text-align: center;
    line-height: 55px;
    color: #8089ff;
    cursor: pointer;
}
#loginBtn:hover {
    background-image: linear-gradient(180deg, #ecfbff 0%, #fff6ff 100%);
}

/* Extra small devices (portrait phones) */
@media (max-width: 575) {
}

/* Small devices (landscape phones) */
@media (max-width: 767) {
}

/* // Medium devices (tablets) */
@media (max-width: 991px) {
    #pageCover .welcome {
        top: 60px;
    }
    #pageCover .text3 {
        left: 35px;
        top: 240px;
    }
    #loginBtn {
        left: 50%;
        margin-left: -108px;
    }
}

/* // Large devices (desktops) */
@media (max-width: 1199) {
}

/* // extra Large devices (FHD desktops) */
@media (max-width: 1499) {
}
