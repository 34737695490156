/* book */
#bookCol {
    height: 100%;
}
#book {
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("/images/book_lg.png");
    height: 440px;
    width: 921px;
    margin: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}
#bookBody {
    /* background: rgba(0, 0, 0, 0.5); */
    height: 240px;
    width: 820px;
    margin: auto;
    position: relative;
}
#bookTitleRow {
    position: absolute;
    top: -100px;
    width: 100%;
}
#bookTitle {
    background-image: url("/images/bookBanner.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 521px;
    height: 105px;
    margin: auto;
}

#bookTitleRow .title {
    position: relative;
    color: #fff;
    font-family: "drabc";
    font-size: 22pt;
    text-align: center;
    margin: auto;
    top: 24px;
}
#bookTitleRow .subTitle {
    position: relative;
    top: 30px;
    margin: auto;
    text-align: center;
    color: #123b77;
    font-size: 21pt;
    font-family: "drabc";
    text-transform: capitalize;
}

#book #readingIcon {
    position: absolute;
    left: 0;
    top: -140px;
    width: 200px;
}
#bookSoundIcon {
    position: absolute;
    right: 0;
    width: 70px;
    top: -70px;
}
#bookSoundIcon #soundIcon {
    width: 100%;
    cursor: pointer;
}
#bookContentRow {
    font-family: "drabc";
    color: #123b77;
    font-size: 18pt;
    text-align: center;
    height: 100%;
    position: relative;
    top: 20px;
}
#bookCarousel,
#bookCarousel .carousel-inner,
#bookCarousel .carousel-item {
    height: 100%;
}
#bookCarousel .carousel-item p {
    text-align: left;
    text-indent: 60px;
}

#bookContentRow b {
    color: #8463ab;
}

#book #leftBtn,
#book #rightBtn {
    width: 136px;
    height: 105px;
    background-image: url(/images/leftBtn_hov.png);
    background-image: url(/images/rightBtn_hov.png);
    background-image: url(/images/leftBtn.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60%;
    position: absolute;
    left: -60px;
    top: 50%;
    margin-top: -82px;
    cursor: pointer;
}
#book #leftBtn {
}
#book #rightBtn {
    background-image: url(/images/rightBtn.png);
    left: auto;
    right: -60px;
}
#book #leftBtn:hover {
    background-image: url(/images/leftBtn_hov.png);
}
#book #rightBtn:hover {
    background-image: url(/images/rightBtn_hov.png);
}

#bookDrIcon {
    position: fixed;
    bottom: 0;
    left: 0;
}
#bookDogIcon {
    position: fixed;
    bottom: 0;
    right: 0;
}
#book p.active {
    color: #ffbb00;
}

/* Extra small devices (portrait phones) */
@media (min-width: 576px) {
}
/* Small devices (landscape phones) */
@media (min-width: 768px) {
    #book {
        background-image: url("/images/book_md.png");
        height: 333px;
        width: 700px;
    }
    #bookBody {
        height: 240px;
        width: 620px;
    }
    #bookTitleRow {
        top: -45px;
    }
    #bookTitle {
        width: 350px;
        height: 70px;
    }
    #bookTitleRow .title {
        font-size: 14pt;
        top: 18px;
    }
    #bookTitleRow .subTitle {
        font-size: 13pt;
        top: 24px;
    }

    #book #readingIcon {
        top: -70px;
        width: 120px;
        left: 40px;
    }
    #bookSoundIcon {
        width: 60px;
        top: -30px;
        right: 15px;
    }

    #bookContentRow {
        font-size: 14pt;
    }

    #book #leftBtn,
    #book #rightBtn {
        background-size: 60%;
    }
    #book #rightBtn {
        left: auto;
    }
}
/* // Medium devices (tablets) */
@media (min-width: 992px) {
    #book {
        background-image: url("/images/book_lg.png");
        height: 440px;
        width: 921px;
    }
    #bookBody {
        height: 240px;
        width: 820px;
    }
    #bookTitleRow {
        top: -110px;
    }
    #bookTitle {
        width: 521px;
        height: 105px;
    }
    #bookTitleRow .title {
        font-size: 22pt;
        top: 24px;
    }
    #bookTitleRow .subTitle {
        font-size: 21pt;
        top: 30px;
    }

    #book #readingIcon {
        top: -140px;
        width: 200px;
        left: auto;
    }
    #bookSoundIcon {
        width: 70px;
        top: -70px;
        right: 0;
    }

    #bookContentRow {
        font-size: 18pt;
    }

    #book #leftBtn,
    #book #rightBtn {
        background-size: 60%;
    }
    #book #rightBtn {
        left: auto;
    }
}

/* // Large devices (desktops) */
@media (min-width: 1200px) {
    /* book */
    #book {
        background-image: url("/images/book_xl.png");
        height: 528px;
        width: 1105px;
    }
    #bookBody {
        height: 300px;
        width: 980px;
    }
    #bookTitleRow {
        top: -110px;
    }
    #bookTitle {
        width: 625px;
        height: 126px;
    }
    #bookTitleRow .title {
        font-size: 26pt;
        top: 30px;
    }
    #bookTitleRow .subTitle {
        font-size: 24pt;
        top: 36px;
    }

    #book #readingIcon {
        width: 240px;
        top: -160px;
    }
    #bookSoundIcon {
        width: 80px;
    }

    #bookContentRow {
        font-size: 21pt;
    }

    #book #leftBtn,
    #book #rightBtn {
        background-size: 80%;
    }
    #book #rightBtn {
        left: auto;
    }
}
/* // extra Large devices (FHD desktops) */
@media (min-width: 1500px) {
    /* book */
    #book {
        background-image: url("/images/book_xxl.png");
        height: 677px;
        width: 1419px;
    }
    #bookBody {
        height: 380px;
        width: 1300px;
    }

    /* title */
    #bookTitleRow {
        top: -150px;
    }
    #bookTitle {
        width: 750px;
        height: 151px;
    }
    #bookTitleRow .title {
        font-size: 32pt;
        top: 40px;
    }
    #bookTitleRow .subTitle {
        font-size: 30pt;
        top: 40px;
    }

    #book #readingIcon {
        width: 320px;
        top: -210px;
        left: 20px;
    }
    #bookSoundIcon {
        width: 100px;
        top: -90px;
        right: 20px;
    }
    /* title end */

    #bookContentRow {
        font-size: 28pt;
    }
    #book #leftBtn,
    #book #rightBtn {
        background-size: 85%;
    }
}
