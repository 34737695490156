/* app.css */
#mainContent > .bg {
    background: url("https://d2z6awdptr1dga.cloudfront.net/portal-images/bg.png")
        bottom center no-repeat;
}

/* home.css */
#logo {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/portal-images/Dr_ABC_logo.png");
}

/* book.css */
#book {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/book_lg.png");
}

#bookTitle {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/bookBanner.png");
}
#book #leftBtn,
#book #rightBtn {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/leftBtn_hov.png");
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/rightBtn_hov.png");
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/leftBtn.png");
}
#book #rightBtn {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/rightBtn.png");
}
#book #leftBtn:hover {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/leftBtn_hov.png");
}
#book #rightBtn:hover {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/rightBtn_hov.png");
}

/* board.css */
#board {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/board_md.png");
}
#board #leftBtn,
#board #rightBtn {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/leftBtn_hov.png");
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/rightBtn_hov.png");
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/leftBtn.png");
}
#board #rightBtn {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/rightBtn.png");
}
#board #leftBtn:hover {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/leftBtn_hov.png");
}
#board #rightBtn:hover {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/rightBtn_hov.png");
}

@media (min-width: 768px) {
    #book {
        background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/book_md.png");
    }
}
@media (min-width: 992px) {
    #book {
        background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/book_lg.png");
    }
    #board {
        background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/board_lg.png");
    }
}

@media (min-width: 1200px) {
    #book {
        background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/book_xl.png");
    }
    #board {
        background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/board_xl.png");
    }
}

@media (min-width: 1500px) {
    #book {
        background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/book_xxl.png");
    }
    #board {
        background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/board_xxl.png");
    }
}

/* completedChallenge */

#mainContent .completedChallenge.bg {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/portal-images/bg_skyCloud.png");
}

/* Extra small devices (portrait phones) */
@media (max-width: 575px) {
}

/* Small devices (landscape phones) */
@media (max-width: 767px) {
}

/* // Medium devices (tablets) */
@media (max-width: 991px) {
}

/* // Large devices (desktops) */
@media (max-width: 1199px) {
}

/* // extra Large devices (FHD desktops) */
@media (max-width: 1499px) {
}
