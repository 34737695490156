@font-face {
    font-family: "drabc";
    src: url("/resources/drAbcRegular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

body {
    position: relative;
    height: 100%;
    min-width: 768px;
    font-family: "Segoe UI";
}
#root {
}
#siteFooter {
    background: #fff;
    height: 60px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    color: #000;
    line-height: 60px;
    text-align: center;
    margin: auto;
}

#siteFooter a {
    margin-right: 20px;
}

#navBar {
    /* Style for "Rectangle" */

    outline: 1px solid #c1c1c1;
    background-color: #fff !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
}

#navBar .navbar-nav li a {
    font-size: 13pt;
}

#navBar > .row {
    height: 100%;
}

#navBar .right > ul > li {
    display: block;
    height: 60px;
}

#pageTitle {
    text-align: center;
    font-size: 20pt;
    line-height: 60px;
    color: #565656;
}
#navUser {
    line-height: 43px;
    font-size: 13pt;
    color: #707070;
    text-transform: capitalize;
}
#navSub {
    height: auto;
}
#navLogo {
    font-size: 14pt;
    margin-top: 6px;
}
#navLogo a {
    text-decoration: none;
    color: #707070;
}
#navLogo img {
    margin-right: 10pt;
}
#navLogo .highlight {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: #ffa319;
}
#navLogo .highlight.a {
    color: #ff738e;
}
#navLogo .highlight.b {
    color: #be68ff;
}
#navLogo .highlight.c {
    color: #53d41d;
}

#navBar + #mainContent {
    padding-top: 75px;
    padding-bottom: 75px;
}

#classRoomBackBtn a {
    color: #707070;
    font-size: 14pt;
    line-height: 43px;
    text-transform: capitalize;
}

#mainContent {
    position: relative;
    min-height: 600px;
}
#mainContent > .bg {
    background: url("/portal-images/bg.png") bottom center no-repeat;
    background-size: cover;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 600px;
}

.container.white {
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 3px 6px #a2a2a2;
    min-height: 90%;
    padding-top: 30px;
    padding-bottom: 30px;
}

.pageHead {
    font-size: 22pt;
    color: #fff;
    margin-bottom: 20px;
}

/* completedChallenge */
.completedChallenge.bg {
    background-image: url("/portal-images/bg_skyCloud.png");
}

/* Extra small devices (portrait phones) */
@media (max-width: 575px) {
}

/* Small devices (landscape phones) */
@media (max-width: 767px) {
}

@media (max-width: 800px) {
    #mainContent > .bg {
        background-position-x: -600px;
    }
}

/* // Medium devices (tablets) */
@media (max-width: 991px) {
    #mainContent > .bg {
        background-position-x: -300px;
    }
}

/* // Large devices (desktops) */
@media (max-width: 1199px) {
}

/* // extra Large devices (FHD desktops) */
@media (max-width: 1499px) {
}

/*  */
/*  */
/*  */

/* Extra small devices (portrait phones) */
@media (min-width: 576px) {
}

/* Small devices (landscape phones) */
@media (min-width: 768px) {
}

/* // Medium devices (tablets) */
@media (min-width: 992px) {
}

/* // Large devices (desktops) */
@media (min-width: 1200px) {
}

/* // extra Large devices (FHD desktops) */
@media (min-width: 1500px) {
}

#mainContent.class {
    background: url("/portal-images/bg2.png") no-repeat top center #266816;
    background-size: 100% auto;
}
#mainContent.class > .bg {
    display: none;
}
#classLogin {
    margin-top: 0px;
    font-family: "drabc";
}

#classLogin .msg {
    background: url("/portal-images/textBox_mobile.png") no-repeat top center;
    background-size: 100% 100%;
}
#classLogin .msg > div {
    padding: 30px 0 30px 30px;
}
#classLogin .msg .line1 {
    font-size: 14pt;
}

#classLogin .msg .line2 {
    font-size: 20pt;
}

.nameCardWrap {
    padding: 8px;
    border-radius: 52px;
    cursor: pointer;
}
.nameCardWrap.active {
    border: 3px solid #ff9900;
}

.nameCard {
    /* width: 349px; */
    height: 120px;
    border-radius: 52px;
}

.blueNameCard {
    /* Style for "Rectangle" */

    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border: 4px solid #94c9f6;
    background-image: -webkit-linear-gradient(top, #1c97ff 0%, #5db4ff 100%);
    background-image: linear-gradient(180deg, #1c97ff 0%, #5db4ff 100%);
}

.orangeNameCard {
    /* Style for "Path 2648" */
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3);
    border: 4px solid #fca63f;
    background-image: -webkit-linear-gradient(top, #e57123 0%, #fb7654 100%);
    background-image: linear-gradient(180deg, #e57123 0%, #fb7654 100%);
}

.purpleNameCard {
    /* Style for "Rectangle" */
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border: 4px solid #bb87f9;
    background-image: -webkit-linear-gradient(top, #833ee5 0%, #5f08e2 100%);
    background-image: linear-gradient(180deg, #833ee5 0%, #5f08e2 100%);
}

.greenNameCard {
    /* Style for "Rectangle" */
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border: 4px solid #bb87f9;
    background-image: -webkit-linear-gradient(top, #833ee5 0%, #5f08e2 100%);
    background-image: linear-gradient(180deg, #833ee5 0%, #5f08e2 100%);
}

.yellowNameCard {
    /* Style for "Rectangle" */
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border: 4px solid #f9db5a;
    background-image: -webkit-linear-gradient(top, #fbc93c 0%, #ff9d09 100%);
    background-image: linear-gradient(180deg, #fbc93c 0%, #ff9d09 100%);
}

.redNameCard {
    /* Style for "Rectangle" */
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border: 4px solid #fc86c2;
    background-image: -webkit-linear-gradient(top, #e83d83 0%, #f8704d 100%);
    background-image: linear-gradient(180deg, #e83d83 0%, #f8704d 100%);
}

.nameCard table {
    margin: auto;
    width: 100%;
    height: 100%;
}
.nameCard table td {
    vertical-align: middle;
    width: 50%;
}
.nameCard .icon {
    padding-left: 20px;
}
.nameCard .icon div {
    background: #fff;
    border-radius: 30px;
    height: 90px;
    width: 90px;
    padding: 15px;
    display: table-cell;
    vertical-align: middle;
}
.nameCard .icon img {
    max-width: 100%;
    max-height: 100%;
}

.nameCard .name {
    text-align: left;
    font-size: 12pt;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    font-family: "drabc";
    padding-right: 8px;
}

#studentLoginBtn {
    /* Style for "Rectangle" */
    width: 300px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    background-color: #ff8500;
    font-family: "Segoe UI";
    color: #fff;
    font-size: 20pt;
    font-weight: bold;
    margin: auto;
    padding: 10px;
    cursor: pointer;
    border: none;
}

#studentLoginBtn:active {
    background-color: #c96c08;
}
#studentLoginBtn:disabled {
    cursor: default;
    background-color: #e6e6e6;
}

#classBeginTestBtn {
    width: 100%;
    padding: 20px;
    text-align: center;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    background-color: #ff8500;
    color: #fff;
    font-family: "Segoe UI";
    font-size: 20pt;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 30px;
    border: none !important;
}

#classSwitchUserBtn {
    width: 100%;
    padding: 20px;
    text-align: center;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    background-color: #e6e6e6;
    color: #666;
    font-family: "Segoe UI";
    font-size: 20pt;
    font-weight: 600;
    border: none !important;
}

#classBeginTestBtn:active {
    background-color: #c96c08;
}
#classSwitchUserBtn:active {
    background-color: #ccc;
}

/* challenge */
#challengeCarousel {
}
#challengeCarousel .navBack {
    position: absolute;
    top: 50%;
    height: 100%;
    left: -5%;
    margin-top: -3%;
}
#challengeCarousel .navNext {
    position: absolute;
    top: 50%;
    height: 100%;
    right: -5%;
    margin-top: -3%;
}

#challengeQuestionNum {
    width: 268px;
    position: absolute;
    left: 50%;
    margin-left: -134px;
    top: -55px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 22px;
    background-color: #ff9900;
    text-align: center;
    padding: 10px;
    color: #fff;
    font-family: "drabc";
    font-size: 18pt;
}

#challengeCarousel_optWrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

#challengeCarousel_optWrap .optWrap {
    margin: 20px 10px;
}

#challengeCarousel_optWrap .optWrap.active {
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    border: 3px solid #ff9900;
}
#challengeCarousel_optWrap .optWrap.answer {
    border: 3px solid greenyellow;
    border-radius: 40px;
}
.challengeOpt {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    border: 5px solid #ececec;
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
    text-align: left;
}

.challengeOpt.box {
    width: 180px;
    height: 180px;
    font-size: 20pt;
    text-align: center;
}
.challengeOpt.list {
    padding: 20px;
    width: 400px;
    font-size: 18px;
}

.challengeOpt_red {
    background-image: -webkit-radial-gradient(
        260.45% 302.27%,
        109px 109px,
        #ff7878 0%,
        #fb3c5a 100%
    );
    background-image: radial-gradient(
        109px 109px at 260.45% 302.27%,
        #ff7878 0%,
        #fb3c5a 100%
    );
}
.challengeOpt_lightbBlue {
    background-image: -webkit-radial-gradient(
        377.73% 302.27%,
        109px 109px,
        #2699fb 0%,
        #178bee 100%
    );
    background-image: radial-gradient(
        109px 109px at 377.73% 302.27%,
        #2699fb 0%,
        #178bee 100%
    );
}
.challengeOpt_green {
    background-image: -webkit-radial-gradient(
        495% 302.27%,
        110px 110px,
        #69f686 0%,
        #2ee164 100%
    );
    background-image: radial-gradient(
        110px 110px at 495% 302.27%,
        #69f686 0%,
        #2ee164 100%
    );
}
.challengeOpt_darkBlue {
    background-image: -webkit-radial-gradient(
        612.27% 302.27%,
        110px 110px,
        #8089ff 0%,
        #6b76ff 100%
    );
    background-image: radial-gradient(
        110px 110px at 612.27% 302.27%,
        #8089ff 0%,
        #6b76ff 100%
    );
}

.challengeOpt.box span.label {
    font-size: 14pt;
    position: absolute;
    bottom: 10px;
    right: 20px;
}

#challengeCarousel .challengeCarousel_btnGroups button {
    border-radius: 20px;
    padding: 20px 40px;
}

/* classroom selection */
#classroomSelection {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    color: #ffffff;
    font-family: "Segoe UI";
    font-size: 30pt;
    font-weight: 700;
    margin-bottom: 30px;
    position: relative;
}

#classroomSelection > div {
    font-size: 14pt;
}

#schoolLanding .gradeBox {
    width: 300px;
    height: 260px;
    box-shadow: 0 3px 6px #a2a2a2;
    border-radius: 36px;
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 20px;
}

#schoolLanding .gradeBox .header {
    color: #2699fb;
    font-family: "Segoe UI";
    font-size: 20pt;
    font-weight: 700;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
#schoolLanding .gradeBox .classLink {
    font-size: 14pt;
}

/* challenge progress bar */

#challengeProgressBar {
    width: 80%;
    height: 5px;
    margin: auto;
    border-radius: 1px;
    background-color: #ffffff;
    position: relative;
    margin-top: 80px;
}

#challengeProgressBar .progress {
    height: 5px;
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 1px;
    background-color: #69f686;
}

#challengeProgressBar .endDot {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: #fbfffe;
    opacity: 0.1;
    position: absolute;
    right: -24px;
    top: -21px;
}
#challengeProgressBar .endKnob {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #ffd500;
    right: -6px;
    top: -3px;
    position: absolute;
}

#challengeProgressBar .progressDot {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: #fbfffe;
    opacity: 0.1;
    position: absolute;
    top: -21px;
    margin-left: -24px;
}
#challengeProgressBar .progressKnob {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #ffd500;
    margin-left: -6px;
    top: -3px;
    position: absolute;
}
#challengeProgressBar .car {
    background: url("/portal-images/yellowCar.png");
    width: 73px;
    height: 40px;
    position: absolute;
    margin-left: -36px;
    top: -50px;
}

#challengeTimer {
    height: 123px;
    position: relative;
}
#challengeTimer .body {
    background: url("/portal-images/timeStamp_sm.png");
    width: 141px;
    height: 123px;
    position: absolute;
    right: 50px;
    top: 2px;
}

#challengeTimer .timer {
    color: #ffffff;
    font-family: "Segoe UI";
    font-size: 21pt;
    font-weight: 600;
    position: relative;
    top: 14px;
    left: 17px;
}

/* Extra small devices (portrait phones) */
@media (min-width: 576px) {
}

/* Small devices (landscape phones) */
@media (min-width: 768px) {
    .challengeOpt.box {
        width: 200px;
        height: 160px;
    }
    .challengeOpt.list {
        width: 480px;
    }
}

/* // Medium devices (tablets) */
@media (min-width: 992px) {
    #classLogin {
        margin-top: 0px;
    }
    .challengeOpt.box {
        width: 180px;
        height: 140px;
    }

    .challengeOpt.list {
        width: 400px;
    }
}

/* // Large devices (desktops) */
@media (min-width: 1200px) {
}

/* // extra Large devices (FHD desktops) */
@media (min-width: 1400px) {
}

/* // extra Large devices (FHD desktops) */
@media (min-width: 1500px) {
    #classLogin {
        margin-top: 40px;
    }
    .challengeOpt.list {
        width: 600px;
    }
}

/* book */
#bookCol {
    height: 100%;
}
#book {
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("/images/book_lg.png");
    height: 440px;
    width: 921px;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
}
#bookBody {
    /* background: rgba(0, 0, 0, 0.5); */
    height: 240px;
    width: 820px;
    margin: auto;
    position: relative;
}
#bookTitleRow {
    position: absolute;
    top: -100px;
    width: 100%;
}
#bookTitle {
    background-image: url("/images/bookBanner.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 521px;
    height: 105px;
    margin: auto;
}

#bookTitleRow .title {
    position: relative;
    color: #fff;
    font-family: "drabc";
    font-size: 22pt;
    text-align: center;
    margin: auto;
    top: 24px;
}
#bookTitleRow .subTitle {
    position: relative;
    top: 30px;
    margin: auto;
    text-align: center;
    color: #123b77;
    font-size: 21pt;
    font-family: "drabc";
    text-transform: capitalize;
}

#book #readingIcon {
    position: absolute;
    left: 0;
    top: -140px;
    width: 200px;
}
#bookSoundIcon {
    position: absolute;
    right: 0;
    width: 70px;
    top: -70px;
}
#bookSoundIcon #soundIcon {
    width: 100%;
    cursor: pointer;
}
#bookContentRow {
    font-family: "drabc";
    color: #123b77;
    font-size: 18pt;
    text-align: center;
    height: 100%;
    position: relative;
    top: 20px;
}
#bookCarousel,
#bookCarousel .carousel-inner,
#bookCarousel .carousel-item {
    height: 100%;
}
#bookCarousel .carousel-item p {
    text-align: left;
    text-indent: 60px;
}

#bookContentRow b {
    color: #8463ab;
}

#book #leftBtn,
#book #rightBtn {
    width: 136px;
    height: 105px;
    background-image: url(/images/leftBtn_hov.png);
    background-image: url(/images/rightBtn_hov.png);
    background-image: url(/images/leftBtn.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60%;
    position: absolute;
    left: -60px;
    top: 50%;
    margin-top: -82px;
    cursor: pointer;
}
#book #leftBtn {
}
#book #rightBtn {
    background-image: url(/images/rightBtn.png);
    left: auto;
    right: -60px;
}
#book #leftBtn:hover {
    background-image: url(/images/leftBtn_hov.png);
}
#book #rightBtn:hover {
    background-image: url(/images/rightBtn_hov.png);
}

#bookDrIcon {
    position: fixed;
    bottom: 0;
    left: 0;
}
#bookDogIcon {
    position: fixed;
    bottom: 0;
    right: 0;
}
#book p.active {
    color: #ffbb00;
}

/* Extra small devices (portrait phones) */
@media (min-width: 576px) {
}
/* Small devices (landscape phones) */
@media (min-width: 768px) {
    #book {
        background-image: url("/images/book_md.png");
        height: 333px;
        width: 700px;
    }
    #bookBody {
        height: 240px;
        width: 620px;
    }
    #bookTitleRow {
        top: -45px;
    }
    #bookTitle {
        width: 350px;
        height: 70px;
    }
    #bookTitleRow .title {
        font-size: 14pt;
        top: 18px;
    }
    #bookTitleRow .subTitle {
        font-size: 13pt;
        top: 24px;
    }

    #book #readingIcon {
        top: -70px;
        width: 120px;
        left: 40px;
    }
    #bookSoundIcon {
        width: 60px;
        top: -30px;
        right: 15px;
    }

    #bookContentRow {
        font-size: 14pt;
    }

    #book #leftBtn,
    #book #rightBtn {
        background-size: 60%;
    }
    #book #rightBtn {
        left: auto;
    }
}
/* // Medium devices (tablets) */
@media (min-width: 992px) {
    #book {
        background-image: url("/images/book_lg.png");
        height: 440px;
        width: 921px;
    }
    #bookBody {
        height: 240px;
        width: 820px;
    }
    #bookTitleRow {
        top: -110px;
    }
    #bookTitle {
        width: 521px;
        height: 105px;
    }
    #bookTitleRow .title {
        font-size: 22pt;
        top: 24px;
    }
    #bookTitleRow .subTitle {
        font-size: 21pt;
        top: 30px;
    }

    #book #readingIcon {
        top: -140px;
        width: 200px;
        left: auto;
    }
    #bookSoundIcon {
        width: 70px;
        top: -70px;
        right: 0;
    }

    #bookContentRow {
        font-size: 18pt;
    }

    #book #leftBtn,
    #book #rightBtn {
        background-size: 60%;
    }
    #book #rightBtn {
        left: auto;
    }
}

/* // Large devices (desktops) */
@media (min-width: 1200px) {
    /* book */
    #book {
        background-image: url("/images/book_xl.png");
        height: 528px;
        width: 1105px;
    }
    #bookBody {
        height: 300px;
        width: 980px;
    }
    #bookTitleRow {
        top: -110px;
    }
    #bookTitle {
        width: 625px;
        height: 126px;
    }
    #bookTitleRow .title {
        font-size: 26pt;
        top: 30px;
    }
    #bookTitleRow .subTitle {
        font-size: 24pt;
        top: 36px;
    }

    #book #readingIcon {
        width: 240px;
        top: -160px;
    }
    #bookSoundIcon {
        width: 80px;
    }

    #bookContentRow {
        font-size: 21pt;
    }

    #book #leftBtn,
    #book #rightBtn {
        background-size: 80%;
    }
    #book #rightBtn {
        left: auto;
    }
}
/* // extra Large devices (FHD desktops) */
@media (min-width: 1500px) {
    /* book */
    #book {
        background-image: url("/images/book_xxl.png");
        height: 677px;
        width: 1419px;
    }
    #bookBody {
        height: 380px;
        width: 1300px;
    }

    /* title */
    #bookTitleRow {
        top: -150px;
    }
    #bookTitle {
        width: 750px;
        height: 151px;
    }
    #bookTitleRow .title {
        font-size: 32pt;
        top: 40px;
    }
    #bookTitleRow .subTitle {
        font-size: 30pt;
        top: 40px;
    }

    #book #readingIcon {
        width: 320px;
        top: -210px;
        left: 20px;
    }
    #bookSoundIcon {
        width: 100px;
        top: -90px;
        right: 20px;
    }
    /* title end */

    #bookContentRow {
        font-size: 28pt;
    }
    #book #leftBtn,
    #book #rightBtn {
        background-size: 85%;
    }
}

#pageCover {
    position: relative;
}

#logo {
    background-image: url(/portal-images/Dr_ABC_logo.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 153px;
    width: 202px;
    top: 60px;
    position: absolute;
}
#pageCover .welcome {
    position: absolute;
    top: 80px;
    left: 230px;
    font-size: 30pt;
    font-weight: 300;
    color: #fff;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
#pageCover .welcome .highlight {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: #ffa319;
    font-size: 30pt;
}
#pageCover .welcome .highlight.a {
    color: #ff738e;
}
#pageCover .welcome .highlight.b {
    color: #be68ff;
}
#pageCover .welcome .highlight.c {
    color: #53d41d;
}
#pageCover .text3 {
    color: #fff;
    font-size: 20pt;
    font-weight: 700;
    position: absolute;
    top: 260px;
    left: 35px;
    text-shadow: 0 3px 20px rgba(0, 0, 0, 1);
}

#loginBtn {
    position: absolute;
    top: 360px;
    left: 35px;
    width: 216px;
    height: 55px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 38px;
    background-image: -webkit-linear-gradient(top, #ffffff 0%, #fff6ff 100%);
    background-image: linear-gradient(180deg, #ffffff 0%, #fff6ff 100%);
    font-size: 20pt;
    font-weight: 600;
    text-align: center;
    line-height: 55px;
    color: #8089ff;
    cursor: pointer;
}
#loginBtn:hover {
    background-image: -webkit-linear-gradient(top, #ecfbff 0%, #fff6ff 100%);
    background-image: linear-gradient(180deg, #ecfbff 0%, #fff6ff 100%);
}

/* Extra small devices (portrait phones) */
@media (max-width: 575) {
}

/* Small devices (landscape phones) */
@media (max-width: 767) {
}

/* // Medium devices (tablets) */
@media (max-width: 991px) {
    #pageCover .welcome {
        top: 60px;
    }
    #pageCover .text3 {
        left: 35px;
        top: 240px;
    }
    #loginBtn {
        left: 50%;
        margin-left: -108px;
    }
}

/* // Large devices (desktops) */
@media (max-width: 1199) {
}

/* // extra Large devices (FHD desktops) */
@media (max-width: 1499) {
}

.classBox {
    width: 100%;
    height: 220px;
    box-shadow: 0 3px 6px #a2a2a2;
    border-radius: 36px;
    background-color: #ffffff;
    margin-bottom: 30px;
    padding: 10px 20px;
    position: relative;
}

.classBox hr {
    border: 1px solid #8089ff;
    margin: 15px 0px;
}

.classBox .enterBtn {
    width: 120px;
    text-align: center;
    position: absolute;
    bottom: 15px;
    left: 50%;
    margin-left: -60px;
}

.unitBox {
    width: 100%;
    box-shadow: 0 3px 6px #a2a2a2;
    border-radius: 36px;
    background-color: #ffffff;
    margin-bottom: 30px;
    padding: 15px 20px 30px;
    position: relative;
    font-size: 15pt;
}

.unitBox .head .lead {
    font-size: 20pt;
    color: #565656;
}

.unitBox hr {
    border: 1px solid #8089ff;
    margin: 15px 0px;
}

/* Extra small devices (portrait phones) */
@media (max-width: 575px) {
}

/* Small devices (landscape phones) */
@media (max-width: 767px) {
}

/* // Medium devices (tablets) */
@media (max-width: 991px) {
}

/* // Large devices (desktops) */
@media (max-width: 1199px) {
}

/* // extra Large devices (FHD desktops) */
@media (max-width: 1499px) {
}

.letterLines .letter_upper_a {
}

.letterLines .letter_lower_a {
}

.letterLines .letter_upper_b {
    bottom: -17px;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}

.letterLines .letter_lower_b {
}

.letterLines .letter_upper_c {
    bottom: -5px;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
}

.letterLines .letter_lower_c {
    bottom: -26px;
    -webkit-transform: scale(0.55);
            transform: scale(0.55);
}

.letterLines .letter_upper_d {
    bottom: -11px;
    -webkit-transform: scale(0.88);
            transform: scale(0.88);
}

.letterLines .letter_lower_d {
    bottom: -27px;
}

.letterLines .letter_upper_e {
    bottom: -34px;
    -webkit-transform: scale(0.63);
            transform: scale(0.63);
}

.letterLines .letter_lower_e {
    bottom: -23px;
}

.letterLines .letter_upper_f {
    bottom: -34px;
    -webkit-transform: scale(0.62);
            transform: scale(0.62);
}

.letterLines .letter_lower_f {
    bottom: -59px;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
}

.letterLines .letter_upper_g {
    bottom: 3px;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.letterLines .letter_lower_g {
    bottom: -59px;
}

.letterLines .letter_upper_h {
    bottom: -18px;
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
}

.letterLines .letter_lower_h {
    bottom: -40px;
    -webkit-transform: scale(0.56);
            transform: scale(0.56);
}

.letterLines .letter_upper_i {
    bottom: -300px;
    -webkit-transform: scale(0.17);
            transform: scale(0.17);
}

.letterLines .letter_lower_i {
    bottom: -220px;
    -webkit-transform: scale(0.18);
            transform: scale(0.18);
}

.letterLines .letter_upper_j {
    bottom: -36px;
    -webkit-transform: scale(0.65);
            transform: scale(0.65);
}

.letterLines .letter_lower_j {
    bottom: -160px;
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
}

.letterLines .letter_upper_k {
    bottom: -25px;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}

.letterLines .letter_lower_k {
    bottom: -70px;
    -webkit-transform: scale(0.34);
            transform: scale(0.34);
}

.letterLines .letter_upper_l {
    bottom: -38px;
    -webkit-transform: scale(0.62);
            transform: scale(0.62);
}

.letterLines .letter_lower_l {
    bottom: -362px;
    -webkit-transform: scale(0.14);
            transform: scale(0.14);
}

.letterLines .letter_upper_m {
    bottom: -12px;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.letterLines .letter_lower_m {
    bottom: -06px;
    -webkit-transform: scale(01);
            transform: scale(01);
}

.letterLines .letter_upper_n {
    bottom: -36px;
    -webkit-transform: scale(0.88);
            transform: scale(0.88);
}

.letterLines .letter_lower_n {
}

.letterLines .letter_upper_o {
    bottom: -2px;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.letterLines .letter_lower_o {
    bottom: -26px;
}

.letterLines .letter_upper_p {
    bottom: -28px;
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
}

.letterLines .letter_lower_p {
    bottom: -65px;
}

.letterLines .letter_upper_q {
    bottom: -24px;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.letterLines .letter_lower_q {
    bottom: -60px;
}

.letterLines .letter_upper_r {
    bottom: -30px;
    -webkit-transform: scale(0.76);
            transform: scale(0.76);
}

.letterLines .letter_lower_r {
    bottom: -50px;
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
}

.letterLines .letter_upper_s {
    bottom: -21px;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}

.letterLines .letter_lower_s {
    bottom: -38px;
    -webkit-transform: scale(0.47);
            transform: scale(0.47);
}

.letterLines .letter_upper_t {
    bottom: -16px;
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
}

.letterLines .letter_lower_t {
    bottom: -58px;
    -webkit-transform: scale(0.35);
            transform: scale(0.35);
}

.letterLines .letter_upper_u {
    bottom: -15px;
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
}

.letterLines .letter_lower_u {
}

.letterLines .letter_upper_v {
    bottom: -20px;
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
}

.letterLines .letter_lower_v {
    bottom: -38px;
}

.letterLines .letter_upper_w {
    bottom: -5px;
    -webkit-transform: scale(1.45);
            transform: scale(1.45);
}

.letterLines .letter_lower_w {
    bottom: -20px;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

.letterLines .upperSVG + .lowerSVG {
    margin-left: 30px;
}

.letterLines .letter_upper_x {
    bottom: -16px;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
}

.letterLines .letter_lower_x {
    bottom: -37px;
    -webkit-transform: scale(0.55);
            transform: scale(0.55);
}

.letterLines .letter_upper_y {
    bottom: -10px;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
}

.letterLines .letter_lower_y {
    bottom: -65px;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
}

.letterLines .letter_upper_z {
    bottom: -2px;
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
}

.letterLines .letter_lower_z {
    bottom: -25px;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
}

/* board */
#boardRow {
    height: 100%;
}
#boardCol {
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: center;
            align-self: center;
    overflow: hidden;
}
#board {
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("/images/board_md.png");
    height: 423px;
    width: 750px;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
}
#boardBody {
    height: 280px;
    width: 520px;
    margin: auto;
    position: relative;
}
#boardTitleRow {
    height: 40px;
}
#board #drIcon {
    position: absolute;
    width: 60%;
    left: 10px;
    top: -10px;
}
#board #soundIcon {
    position: absolute;
    width: 60%;
    right: 15px;
    top: -0px;
    cursor: pointer;
}

#board .titleWrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}
#board .title {
    font-family: "drabc";
    color: #ffffff;
    font-size: 17pt;
    text-align: center;
}
#boardContentRow {
    font-family: "drabc";
    color: #ffffff;
    font-size: 17pt;
    text-align: left;
}
#boardContentRow p:first-child {
    margin-bottom: 3%;
}
#boardContentRow b {
    color: #ffbb00;
}

#board #leftBtn,
#board #rightBtn {
    width: 136px;
    height: 105px;
    background-image: url(/images/leftBtn_hov.png);
    background-image: url(/images/rightBtn_hov.png);
    background-image: url(/images/leftBtn.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60%;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -52px;
    cursor: pointer;
}
#board #leftBtn {
}
#board #rightBtn {
    background-image: url(/images/rightBtn.png);
    left: auto;
    right: 0;
}
#board #leftBtn:hover {
    background-image: url(/images/leftBtn_hov.png);
}
#board #rightBtn:hover {
    background-image: url(/images/rightBtn_hov.png);
}

#board p.active {
    color: #ffbb00;
}

/* Extra small devices (portrait phones) */
@media (min-width: 576px) {
}
/* Small devices (landscape phones) */
@media (min-width: 768px) {
}
/* // Medium devices (tablets) */
@media (min-width: 992px) {
    #board {
        background-image: url("/images/board_lg.png");
        height: 560px;
        width: 950px;
    }
    #boardBody {
        height: 360px;
        width: 720px;
    }
    #boardTitleRow {
        height: 72px;
    }
    #board .title {
        font-size: 20pt;
    }
    #boardContentRow {
        font-size: 20pt;
    }
}

/* // Large devices (desktops) */
@media (min-width: 1200px) {
    #board {
        background-image: url("/images/board_xl.png");
        height: 630px;
        width: 1070px;
    }
    #boardBody {
        height: 410px;
        width: 800px;
    }
    #boardTitleRow {
        height: 82px;
    }
    #board .title {
        font-size: 25pt;
    }
    #boardContentRow {
        font-size: 23pt;
    }
}
/* // extra Large devices (FHD desktops) */
@media (min-width: 1500px) {
    #board {
        background-image: url("/images/board_xxl.png");
        height: 780px;
        width: 1360px;
    }
    #boardBody {
        height: 510px;
        width: 1020px;
    }
    #soundIcon {
        top: 0;
    }
    #boardTitleRow {
        height: 102px;
    }
    #board .title {
        font-size: 31pt;
    }
    #boardContentRow {
        font-size: 29pt;
    }
    #board #leftBtn,
    #board #rightBtn {
        background-size: 75%;
        left: 20px;
    }
    #board #rightBtn {
        background-size: 75%;
        left: auto;
        right: 20px;
    }
}

#Layer_1 {
    width: 100px;
}

.upperSVG svg g *:not(.cover) {
    stroke: #fff;
}

.upperSVG svg g .cover,
.lowerSVG svg g .cover {
    fill: #00ad46 !important;
}

.animate1 {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    stroke: #fff !important;
    -webkit-animation: dash 1s ease-in 0s forwards;
            animation: dash 1s ease-in 0s forwards;
}
.animate2 {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    stroke: #fff !important;
    -webkit-animation: dash 1s ease-in 0s forwards;
            animation: dash 1s ease-in 0s forwards;
}
.animate3 {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    stroke: #fff !important;
    -webkit-animation: dash 1s ease-in 0s forwards;
            animation: dash 1s ease-in 0s forwards;
}
@-webkit-keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

.letterLines {
    max-width: 360px;
    height: 110px;
    border: 0;
    border-top: 1px solid #ffbb00;
    border-bottom: 1px solid #ffbb00;
    position: relative;
    margin: auto;
}
.letterLines > div.mid {
    border-bottom: 1px dashed #ffbb00;
    height: 50px;
}
.letterLines .upperSVG,
.letterLines .lowerSVG {
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -56px;
}
.letterLines .lowerSVG {
    bottom: -30px;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
}
.letterLines .upperSVG + .lowerSVG {
    margin-left: -10px;
}
.letterLines span.upperSVG {
    margin-left: -100px;
}

/* app.css */
#mainContent > .bg {
    background: url("https://d2z6awdptr1dga.cloudfront.net/portal-images/bg.png")
        bottom center no-repeat;
}

/* home.css */
#logo {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/portal-images/Dr_ABC_logo.png");
}

/* book.css */
#book {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/book_lg.png");
}

#bookTitle {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/bookBanner.png");
}
#book #leftBtn,
#book #rightBtn {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/leftBtn_hov.png");
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/rightBtn_hov.png");
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/leftBtn.png");
}
#book #rightBtn {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/rightBtn.png");
}
#book #leftBtn:hover {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/leftBtn_hov.png");
}
#book #rightBtn:hover {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/rightBtn_hov.png");
}

/* board.css */
#board {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/board_md.png");
}
#board #leftBtn,
#board #rightBtn {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/leftBtn_hov.png");
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/rightBtn_hov.png");
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/leftBtn.png");
}
#board #rightBtn {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/rightBtn.png");
}
#board #leftBtn:hover {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/leftBtn_hov.png");
}
#board #rightBtn:hover {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/rightBtn_hov.png");
}

@media (min-width: 768px) {
    #book {
        background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/book_md.png");
    }
}
@media (min-width: 992px) {
    #book {
        background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/book_lg.png");
    }
    #board {
        background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/board_lg.png");
    }
}

@media (min-width: 1200px) {
    #book {
        background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/book_xl.png");
    }
    #board {
        background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/board_xl.png");
    }
}

@media (min-width: 1500px) {
    #book {
        background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/book_xxl.png");
    }
    #board {
        background-image: url("https://d2z6awdptr1dga.cloudfront.net/images/board_xxl.png");
    }
}

/* completedChallenge */

#mainContent .completedChallenge.bg {
    background-image: url("https://d2z6awdptr1dga.cloudfront.net/portal-images/bg_skyCloud.png");
}

/* Extra small devices (portrait phones) */
@media (max-width: 575px) {
}

/* Small devices (landscape phones) */
@media (max-width: 767px) {
}

/* // Medium devices (tablets) */
@media (max-width: 991px) {
}

/* // Large devices (desktops) */
@media (max-width: 1199px) {
}

/* // extra Large devices (FHD desktops) */
@media (max-width: 1499px) {
}

